@import "./src/common/commonStyles";

.description {
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    margin-bottom: 20px;
}

.title {
    color: $title-color;
    font-size: 16px;
    font-weight: 600;
}

.text {
    margin-top: 8px;
    color: $description-color;
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 600;
}