@import "../../common/commonStyles";

.container {
    position: relative;
    @include flexible();
    justify-content: center;
    background-color: #F3F3F3;
    width: 100%;
    height: 200px;
    margin: 20px 0;
}

.img {
    display: block;
    width: 100%;
    max-width: 80px;
}