@import "./src/common/commonStyles";

.container {
    position: relative;
    padding-top: 30px;
}

.top {
    padding: 0 30px 180px;
    margin-bottom: 10px;
}

.scannerContainer {
    position: relative;
    z-index: 1;
}

.borderUpper {
    position: absolute;
    border: 5px solid #f2f3f5;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    margin: auto;
    border-radius: 20px;
    z-index: 3;
    pointer-events: none;
}

.rotate {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: transparent;
    z-index: 2;

    & img {
        width: 25px;
        display: block;
    }
}

.rule {
    font-size: 16px;
    font-weight: 600;
    color: $title-color;
    text-align: center;
    margin-bottom: 20px;
}

.empty {
    height: 63px;
}