@import "./src/common/commonStyles";

.container {
    overflow-y: auto;
    background-color: #f2f3f7;
    min-height: 101vh;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 10;
}

.title {
    pointer-events: auto;
    color: white;
}

.top {
    padding-bottom: 30px;
}

.name {
    padding: 30px 30px 0;
}

.content {
    padding: 0 30px;
}

.audioContainer {
    margin-bottom: 20px;
}

