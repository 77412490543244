@import "./src/common/commonStyles";

.input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    & input {
        padding: 21px 0 19px 50px;
        width: 100%;
        border: 1px solid rgba(8, 29, 63, 0.15);
        border-radius: 10px;
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: $title-color;
        &::placeholder {
            color: rgba(8, 29, 63, 0.25);
        }
    }

    & label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 22px;
        margin: auto;
        @include flexible();
    }

}