@import "./src/common/commonStyles";

.ticketRemaining {
    color: $primary-color;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 0 15%;

    & span {
        white-space: nowrap;
        text-decoration: underline;
    }
}