@import "./src/common/commonStyles";

.container {
    display: block;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 12px;
}

.imgContainer {
    & img {
        display: block;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        min-height: 200px;
    }
}

.info {
    padding: 20px;
}

.name {
    overflow-wrap: break-word;
    text-align: center;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 600;
    color: $title-color;
}

.link {
    @include flexible();
    justify-content: center;


    & span {
        font-size: 16px;
        font-weight: 600;
        color: $primary-color;
    }

    & svg {
        width: 11px;
        margin-left: 5px;
    }
}