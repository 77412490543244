@import "./src/common/commonStyles";

.roomContainer {
    max-width: 100%;
    @include flexible();
    border-bottom: 1px solid rgba(8, 29, 63, 0.2);
    padding: 20px 0;
}

.imgContainer {
    margin-right: 12px;
    position: relative;
}
.img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
}

.absence {
    padding: 13px;
    height: 50px;
    display: block;
    width: 50px;
}

.circle {
    position: absolute;
    pointer-events: none;
    border: 2px solid white;
    border-radius: 25px;
    background-color: transparent;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    margin: auto;
}

.name {
    color: $title-color;
    font-size: 16px;
    font-weight: 600;
    @include flexible();
    max-width: calc(100% - 60px);

    & span {
    //white-space: nowrap;
    //overflow-x: hidden;
    //text-overflow: ellipsis;
    }

    & img {
        display: block;
        margin-left: 8px;
    }
}