.outer {
    position: relative;
    background-color: #f2f3f7;
}

.container {
    background-color: #f2f3f7;
    max-width: 480px;
    margin: 0 auto;
}

.qr-image-wrapper {
    position: relative;
}

.qr-image-wrapper section {
    border-radius: 12px;
}

.swiper-slide-shadow {
    border-radius: 15px;
}

.swiper-wrapper {
    min-height: 1vh;
    align-items: center;
}

.swiper-slide {
    min-height: 1vh;
}

.swiper-cards {
    overflow: hidden !important;
    padding: 0 20px !important;

    & .swiper-wrapper {
        min-height: 0;
    }
}

.pageTitle {
    overflow-wrap: break-word;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #081D3F;
    margin-top: 20px;
    padding: 0 30px;

    & a, button img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
    }
}

.error {
    position: relative;
    background: rgba(237, 89, 89, 0.1);
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    color: #ED5959;
    animation-iteration-count: infinite;
    animation: shake 1s;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    margin: 8px auto 0 !important;
    padding: 0 !important;
}

.blinking-shadow {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: #7764F5;
    z-index: 20;
    width: 15px;
    filter: blur(9px);
    animation-name: blink;
    animation-timing-function: ease-in;
    animation-duration: 1s;
    animation-iteration-count: 3;
    opacity: 0;
}

.right-shadow {
    border-radius: 100% 0 0 100%;
    right: -3px;
}

.left-shadow {
    border-radius: 0 100% 100% 0;
    left: -3px;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}