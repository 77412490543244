@import "./src/common/commonStyles";

.paginator {
    margin: 30px auto;
    text-align: center;
    padding: 5px 10px;
    width: max-content;
    border-radius: 5px;
}

.pageNumber {
    margin: 0 2px;
    padding: 2px;
    cursor: pointer;
    color: $title-color;
    font-weight: 600;
}

.selectedPage {
    font-weight: 700;
}

.btn {
    width: 80px;
    cursor: pointer;
    margin: 0 15px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: transparent;
    color: $title-color;
}

@media (max-width: 480px) {
    .pageNumber {
        font-size: 12px;
    }
    .paginator {
        padding: 5px 0;
    }

    .btn {
        width: 44px;
        font-size: 12px;
    }
}
