@import "./src/common/commonStyles";

.container {
    cursor: pointer;
    margin: 30px 0;
    padding: 15px 20px;
    border: 1px solid $primary-color;
    box-shadow: 0 10px 23.1884px rgba(127, 84, 235, 0.25);
    border-radius: 12px
}

.name {
    font-size: 16px;
    margin-bottom: 5px;
    color: $title-color;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;

}

.type {
    font-size: 14px;
    color: $primary-color;
}