@import "./src/common/commonStyles";

.container {
    padding-top: 30px;
    position: relative;
}

.top {
    padding: 0 30px 187px;
}

.empty {
    height: 65px;
}