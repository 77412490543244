.display {
    display: none;
}
.trans, .transition {
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: opacity 1s ease 0s;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(255,255,255, 1);
}

.trans {
    opacity: 0;
    /*display: none;*/
}

.mireaImg {
    width: 160px;
    height: 180px;
}

.crossImg {
    margin: 20px 25px;
}

.preloaderImagesContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}
.laba {
    display: block;
    width: 51px;
    height: 43px;
    margin-right: 15px;
}
.reality {
    display: block;
    width: 135px;
    height: 40px;
}