* {
  font-family: 'Proxima Nova', sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
}
