@import "./src/common/commonStyles";

.footer {
    @include fixedFooterContainer();
}

.button {
    color: white;
    @include flexible();
    justify-content: center;
    padding: 20px 0;
    border-radius: 12px;

    & svg {
        display: block;
        margin-left: 6px;
    }
}

.link {
    transition: all 0.4s ease;
    &:active {
        background-color: $primary-active;
    }

    background-color: $primary-color;
}

.block {
    background-color: $primary-disabled;
}