.preloaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    /*background-color: #505050;*/
}
.preloaderContainer img {
    display: block;
    width: 30px;
}

