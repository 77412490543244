@import "./src/common/commonStyles";

.modal {
    width: 500px;
    margin: 0 auto;
}

.title {
    color: $title-color;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
}

.text {
    margin-bottom: 25px;
    color: $description-color;
    font-size: 18px;
    font-weight: 600;
}

.confirmBtn {
    font-size: 16px;
    font-weight: 600;
    color: white;
    display: block;
    background-color: $primary-color;
    transition: all 0.4s ease;
    border-radius: 10px;
    padding: 15px 0;
    width: 80%;
    margin: auto;
    cursor: pointer;

    &:active {
    background-color: $primary-active;
    }
}