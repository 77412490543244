@import "./src/common/commonStyles";

.container {
    position: relative;
    padding: 30px 0 0;
    height: 100%;
}

.top {
    padding: 0 30px 280px;
}

.nav {
    @include flexible();
    justify-content: space-between;
}

.exitBtn {
    color: $primary-color;
    border: 1px solid $primary-color;
    background-color: transparent;
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 12px;
}

.name {
    word-wrap: break-word;
    margin: 15px 0 12px;
    font-size: 18px;
    font-weight: 600;
    color: $title-color;
    & div {
        color: $primary-color;
    }
}

@media (max-width: 310px) {
    .exitBtn {
        margin-left: 5px;
        font-size: 11px;
        padding: 5px 7px;
    }
}