@import "./src/common/commonStyles";

.imgContainer {
    margin: 20px auto;
    min-height: 200px;

    & img {
        display: block;
        width: 100%;
        max-height: 300px;
        border-radius: 12px;
        object-fit: cover;
    }
}
