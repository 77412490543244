@import "./src/common/commonStyles";

.item {
    position: relative;
    z-index: 2;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 25px 1fr;
    grid-template-areas:
    'number text'
    'smth img'
    'nav nav'
;
    background-color: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 5px 15px 50px rgba(8, 29, 63, 0.05);
}

.number {
    @include flexible();
    grid-area: number;
    font-size: 37px;
    font-weight: 800;
    color: $title-color;
    opacity: .1;
    text-align: center;
}

.text {
    grid-area: text;
    font-size: 14px;
    font-weight: 600;
    color: $title-color;
}

.imgContainer {
    grid-area: img;
    width: max-content;
    margin: 0 auto 10px;

    & span img {
        height: 200px;
        display: block;
        width: 100%;
    }
}

.nav {
    grid-area: nav;
    @include flexible();
    justify-content: space-between;
}

.skip {
    cursor: pointer;
    color: rgba(8, 29, 63, 0.3);
    font-size: 14px;
    transition: all 0.4s ease;

    &:hover {
        color: rgba(8, 29, 63, 0.6);
    }
}

.next {
    cursor: pointer;
    @include flexible();

    & span {
        font-size: 14px;
        color: $primary-color;
        font-weight: 600;
    }

    & svg {
        display: block;
        margin-left: 7px;
    }
}

.last {
    grid-area: nav;
    @include flexible();
    justify-content: center;
}