@import "./src/common/commonStyles";

.room {
    padding: 20px 20px 0;
    margin-top: 20px;
    border-radius: 12px;
}

.unvisited {
    background-color: #fff;

    & div:first-child {
        color: $primary-color;
    }

    & a:last-child {
        border: none;
    }
}

.visited {
    border: 1px solid rgba(8, 29, 63, 0.2);

    & div:first-child {
        color: #00CE84;
    }

    & a:last-child {
        border: none;
    }
}

.title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}