@import "./src/common/commonStyles";

.activeBtn {
    border: 1px solid #00CE84;
    color: white;
    background-color: #00CE84;
}

.noActive {
    border: 1px solid #4A6375;
    color: #4A6375;
    background-color: transparent;

}

.btn {
    margin-bottom: 25px;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 11px;
    margin-right: 10px;
}