@import "./src/common/commonStyles";

.link {
    width: 100%;
    background: #eceef1;
    padding: 21px 0;
    border-radius: 12px;
    color: $title-color;
    @include flexible();
    justify-content: center;
    transition: all 0.4s ease;
    &:active {
        background: #D9DDE3;
    }
}

.text {
    font-size: 14px;
    font-weight: 600;
}

.icon {
    width: 18px;
    margin-right: 6px;
}