@import "./src/common/commonStyles";

.header {
    background-color: white;
    margin: 0 auto 30px;
}

.headerContainer {
    margin: 0 auto;
    @include flexible();
    justify-content: space-between;
    padding: 35px 0;
    max-width: 1600px;
}

.buttons {
    @include flexible();
    gap: 15px;
}

.printBtn {
    background-color: $primary-color;
    border-radius: 15px;
    transition: all 0.4s ease;
    font-size: 16px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    @include flexible();

    & div {
        background-color: white;
        @include flexible();
        justify-content: center;
        border-radius: 50%;
        min-width: 30px;
        min-height: 30px;
        text-align: center;
        margin-left: 10px;
        color: $primary-color;
    }

    &:active {
        background-color: $primary-active;
    }
    &:disabled {
        background-color: $primary-disabled;
    }
}

.logout {
    cursor: pointer;
    padding: 15px 20px;
    color: $title-color;
    font-size: 16px;
    font-weight: 600;
    display: block;
    background-color: transparent;
    border: 1px solid rgba(8, 29, 63, 0.15);
    border-radius: 15px;
}

.printComponentContainer {
    display: none;
}

@media (max-width: 1600px) {
    .headerContainer {
        padding: 20px 40px;
    }
}