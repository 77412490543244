@import "./src/common/commonStyles";

.nav {
    @include flexible();
    margin-right: 30px;
}

.logo {
    margin-right: 150px;
    & img {
        display: block;
        width: 120px;
    }
}

.linksContainer {
    @include flexible();
    gap: 73px
}