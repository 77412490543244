@import "../../../../common/commonStyles";

.top {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 48px;
    margin-bottom: 25px;
}

.name {
    @include flexible();
}

.exposition {
    font-size: 22px;
    font-weight: 600;
    margin-left: 10px;
    color: rgba(8, 29, 63, 0.6);
}