@import "./src/common/commonStyles";

.container {
    padding: 30px;
    max-width: 480px;
    margin: 0 auto;
}

.top {
    padding-bottom: 20px;
}

.errorImg {
    text-align: center;
    margin: 30px auto 0;
    & img {
        height: 35vh;
    }
}

.error {
    background: rgba(237, 89, 89, 0.1);
    border-radius: 15px;
    padding: 20px;
}

.title {
    text-align: center;
    color: $error-text-color;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 20px;
    position: relative;
    @include flexible();
    flex-direction: column;

    &:after {
        content: '';
        width: 40px;
        height: 2px;
        background: rgba(237, 89, 89, 0.2);
        position: absolute;
        bottom: -50%;
    }
}

.rules {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: rgba(237, 89, 89, 0.75);
}

.footer {
    //@include fixedFooterContainer();
}

.buttonBack {
    @include bottomButton();

    & svg {
        display: block;
        margin-right: 6px;
    }
}