@import "./src/common/commonStyles";

.stepContainer {

}

.points {
    @include flexible();
    justify-content: center;
    margin-bottom: 15px;
}

.point {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgba(38, 50, 56, 0.15);
    margin: 0 3px;
}

.active {
    background-color: $primary-color;
}

.step {
    text-align: center;
    color: $primary-color;
    font-size: 14px;
    font-weight: 600;
}