@import "./src/common/commonStyles";

.container {
    margin-bottom: 20px;
}

.audio {
    background-color: #ebedf1;
    max-width: 650px;
    margin: 0 auto;
    border-radius: 12px;
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
    color: $description-color;
    word-break: break-all;
}