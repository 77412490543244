@import "./src/common/commonStyles";

.container {
    padding: 30px;
    max-width: 480px;
    margin: 0 auto;
}

.top {
    padding-bottom: 100px;
}

.errorImg {
    text-align: center;
    margin: 30px auto 0;
    & img {
        height: 320px;
    }
}

.title {
    white-space: pre-wrap;
    text-align: center;
    color: $title-color;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
}

.footer {
    @include fixedFooterContainer();
}

.buttonBack {
    @include bottomButton();

    & svg {
        display: block;
        margin-right: 6px;
    }
}