@import "./src/common/commonStyles";

.sliderContainer {
    min-height: 200px;
    margin: 20px 0;
    position: relative;
}

.slideIco {

    position: absolute;
    right: 0;
    bottom: 0;

    pointer-events: none;


    & svg {
        right: 30px;
        bottom: 40px;
        position: relative;
        z-index: 2;
        display: block;
    }
}

.blur {
    right: 0;
    bottom: 10px;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    background: $primary-color;
    opacity: 0.85;
    filter: blur(40px);
    width: 100px;
    height: 100px;
}