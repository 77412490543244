@import "../../../../../common/commonStyles";

.artifacts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}

.notFound {
    @include notFound();
}

@media (max-width: 1200px) {
    .artifacts {
        grid-template-columns: 1fr 1fr;
    }
}
