@import "./src/common/commonStyles";

.arrow {
    width: 55px;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: transparent;
    bottom: 0;
    margin: auto;
    z-index: 1;
    @include flexible();
    align-self: center;
    cursor: pointer;
    opacity: 1;

    & div {
        border-radius: 13px;
        background-color: white;
        width: max-content;
    }

    & div img {
        height: inherit;
        display: block;
    }
}

.left {
    left: 0;
    & div {
        margin-left: auto;
    }
}

.right {
    right: 0;
}

.fullscreen {
    position: absolute;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 7px;
    z-index: 1;
    padding: 6px;
    cursor: pointer;
    right: 15px;
    top: 15px;

    & img {
        display: block;
    }
}