@import "./src/common/commonStyles";

.descriptionContainer {
    background-color: #F8FAFB;
    padding: 35px 8%;
    border-radius: 15px;
}

.title {
    font-weight: bold;
    font-size: 18px;
    color: $title-color;
    margin-bottom: 12px;
    text-align: center;
}

.wrapperBorder {
    width: 35px;
    margin: 0 auto 12px;
    border-radius: 5px;
    border-bottom: 2px solid $primary-color;
}

.description {
    color: $description-color;
    font-size: 14px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    margin-bottom: 15px;
}