@import "./src/common/commonStyles";

.container {
    //padding: 30px;
    max-width: 480px;
}

.top {
    margin-bottom: 10px;
    padding: 30px 30px 0;
}

.content {
    margin-bottom: 50px;
    padding: 0 5px 30px;
    position: relative;

}