@import "./src/common/commonStyles";

.container {
    position: relative;
    width: max-content;
    margin: 0 auto;
}

.map {
    display: block;
}