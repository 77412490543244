@import "./src/common/commonStyles";

.modal {
    width: 500px;
    margin: 0 auto;
}

.title {
    color: $title-color;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
}

.text {
    margin-bottom: 25px;
    color: $description-color;
    font-size: 18px;
    font-weight: 600;
}

.buttons {
    @include flexible();
    margin-bottom: 25px;
    gap: 20px;

    & button {
        margin-bottom: 25px;
        cursor: pointer;
        border-radius: 10px;
        padding: 8px 11px;
    }
}

.button {
    border: 1px solid #4A6375;
    color: #4A6375;
    background-color: transparent;
}

.active {
    border: 1px solid #00CE84;
    color: white;
    background-color: #00CE84;
}

.printButton {
    font-size: 16px;
    font-weight: 600;
    color: white;
    display: block;
    background-color: $primary-color;
    transition: all 0.4s ease;
    border-radius: 10px;
    padding: 15px 0;
    width: 80%;
    margin: auto;
    cursor: pointer;

    &:active {
        background-color: $primary-active;
    }
    &:disabled {
        background-color: $primary-disabled;
    }
}

.printComponentContainer {
    display: none;
}