@import "./src/common/commonStyles";

.container {
    position: relative;
}
.top {
    padding: 30px 30px 120px;
}

.current {
    color: #00CE84;
    text-align: center;
    margin: 30px auto;
    font-weight: 600;
}

.roomName {
    font-weight: 600;
    color: $title-color;
    margin-top: 5px;
    text-align: center;
    overflow-wrap: break-word;
}

.map {
    @include fixedFooterContainer();
}

.link {
    @include bottomButton();

    & img {
        display: block;
        margin-right: 8px;
    }
}

.noRooms {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: $title-color;
}