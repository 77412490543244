@import "./src/common/commonStyles";

.container {
    @include flexible();
    gap: 10px;
}

.imgContainer {
    width: 40px;
    height: 40px;
    @include flexible();
    justify-content: center;
    background: rgba(119, 100, 245, 0.1);
    border-radius: 50%;
    & svg {
        display: block;
    }
}

.title {
    font-size: 22px;
    font-weight: 700;
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(8, 29, 63, 0.15);
}