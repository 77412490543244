@import "./src/common/commonStyles";

.navigation {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 20px 30px 45px;
    max-width: 480px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    background: linear-gradient(180deg, $buttons-color 0%, #FFFFFF 100%);
    grid-gap: 10px;
    grid-template-areas:
    "id help"
    "map map"
    "qr qr";
}

.map {
    grid-area: map;
}

.qrBtn {
    grid-area: qr;
}

.icoBtn {
    grid-area: ico;
}