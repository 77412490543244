@import "./src/common/commonStyles";

.container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    background-color: white;
    width: 600px;
    height: 470px;
    padding: 46px 145px;
    box-shadow: 10px 30px 100px rgba(69, 48, 124, 0.05);
}

.logo {
    display: block;
    & img {
        margin: 0 auto;
        display: block;
        width: 120px;
    }
}

.line {
    margin: 15px auto;
    height: 2px;
    background: rgba(8, 29, 63, 0.05);
    width: 70px;
}

.title {
    color: $title-color;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 39px;
}

.button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    transition: all 0.4s ease;
    color: white;
    background-color: $primary-color;
    @include flexible();
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 15px 30px rgba(127, 84, 235, 0.15);

    &:active {
        background-color: $primary-active;
    }
}