@import "./src/common/commonStyles";

.container {
    position: relative;
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 2px;
    background: linear-gradient(180deg, #7764F5 0%, #E754C9 100%);
}

.visited {
    background: #C3C8D2;
}

.inner {
    position: relative;
    background-color: white;
    padding: 1px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    & img {
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
}

.itemImg {
    object-fit: cover;
}

.absence {
    object-fit: contain ;
}

.dot {
    opacity: 0;
    position: relative;
    background-color: #E754C9;
    width: 5px;
    height: 5px;
    left: 0;
    right: 0;
    border-radius: 50%;
    margin: 5px auto 0;
}

.current {
    opacity: 1;
}