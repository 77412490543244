@import "./src/common/commonStyles";

.container {
    position: relative;
}

.top {
    padding: 30px 30px 0;
}

.map {
    overflow: auto;
    margin: 20px 0;
    width: 100%;
}

.rule {
    margin-bottom: 20px;
    text-align: center;
    color: rgba(8, 29, 63, 0.5);
    padding: 0 40px;
    font-weight: 600;
}

.legendContainer {
    padding: 0 30px 20px;
    //margin-bottom: 20px;
}

.legend {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: $title-color;
}

.legendItem {
    color: $description-color;
    @include flexible();
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;

    & img {
        width: 12px;
        display: block;
        margin-right: 8px;
    }
}

.back {
    max-width: 480px;
    padding: 0 30px 45px;
    background-color: $buttons-color;
}

.buttonBack {
    @include bottomButton();

    & img {
        display: block;
        width: 10px;
        margin-right: 6px;
    }

}

.notFound {
    @include notFound();
}