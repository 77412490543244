@import "./src/common/commonStyles";

.container {
    cursor: pointer;
    position: absolute;
}

.name {
    max-width: 80px;
    color: $title-color;
    position: relative;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    max-height: 30px;
    overflow: hidden;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
}

.round {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}