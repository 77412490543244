@import "./src/common/commonStyles";

.print {

}

.content {
    margin: 0 auto;
    max-width: 1600px;
    border-radius: 10px;
    padding: 40px;
    background-color: white;
}

.modal {
    width: 580px !important;
    margin: 0 auto;
    padding: 25px 25px 40px 40px;
    background-color: white;
    box-shadow: 10px 10px 75px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}


@media print {
    .printComponentContainer {
        display: block;
    }
}

@media (max-width: 1200px) {
    .logo {
        margin-right: 80px;
    }
    .linksContainer {
        gap: 40px;
    }
    .printBtn, .logout, .link {
        font-size: 14px;
    }
}
