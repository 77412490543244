@import "./src/common/commonStyles";

.footer {
  position: fixed;
  padding: 45px 30px;
  background: linear-gradient(180deg, $buttons-color, #FFFFFF);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 480px;
  z-index: 11;
}

.question {
  text-align: center;
  color: rgba(8, 29, 63, 0.35);
  margin-bottom: 12px;
}

.enterId {
  border-radius: 12px;
  background-color: $buttons-color;
  @include flexible();
  justify-content: center;
  padding: 20px 0;

  & img {
    width: 18px;
    margin-right: 6px;
  }
  & span {
    color: $title-color;
    font-size: 14px;
    font-weight: 600;
  }
}