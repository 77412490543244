@import "./src/common/commonStyles";

.inputContainer {
    margin: 20px 0;

    & input {
        font-size: 14px;
        font-weight: 600;
        color: $title-color;
        padding: 20px;
        width: 100%;
        border-radius: 12px;

        &::placeholder {
            color: rgba(8, 29, 63, 0.25);
        }
    }
}

.prompt {
    color: rgba(8, 29, 63, 0.35);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}