@import "./src/common/commonStyles";

.link {
    color: $title-color;
    opacity: .5;
    @include flexible();
    & svg {
        margin-right: 10px;
        display: block;
    }
}

.active {
    color: $primary-color;
    opacity: 1;
}