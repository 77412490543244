@import "./src/common/commonStyles";

.container {
    max-width: 480px;
    padding: 50px 30px 46px;
}

.logo {
    width: 120px;
    margin: 0 auto 10px;
    display: block;
}

.line {
    width: 35px;
    height: 1.5px;
    background-color: $primary-color;
    border-radius: 5px;
    margin: 0 auto 10px;
}

.description {
    padding: 0 15px;
    color: $title-color;
    opacity: .5;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 40px;
}

.welcomeContainer {
    & img {
        display: block;
        margin: 0 auto;
        height: 260px;
    }
    margin: 0 auto 30px;
}

.navigation {
    display: grid;
    grid-gap: 8px;
    grid-template-areas:
    'help qr'
    'expositions expositions'
;
}

.qrBtn {
    grid-area: qr;
}
.helpBtn {
    grid-area: help;
}
.expositionsBtn {
    grid-area: expositions;
}