@import "./src/common/commonStyles";

.container {
    position: relative;
    padding: 20px 0;
    left: -30px;
    width: calc(100% + 60px);
    border-bottom: 1px solid rgba(8, 29, 63, 0.1);
}

.inner {
    position: relative;
}