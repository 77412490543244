@import "./src/common/commonStyles";

.container {
    position: relative;
}

.top {
    padding: 30px 30px 110px;
}

.button {
    @include fixedFooterContainer()
}

.link {
    @include bottomButton();

    & img {
        display: block;
        width: 18px;
        margin-right: 5px;
    }
}