$primary-color: #7764F5;
$primary-active: #5942F5;
$primary-disabled: #998AF9;
$title-color: #081D3F;
$description-color: #4A6375;
$buttons-color: #f2f3f5;
$buttons-active: #f2f3f5;
$error-text-color: #ED5959;

@mixin flexible {
    display: flex;
    align-items: center;
}

@mixin shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@mixin fixedFooterContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 480px;
    padding: 10px 30px 45px;
    background-color: $buttons-color;
}
@mixin bottomButton {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    transition: all 0.4s ease;
    color: white;
    background-color: $primary-color;
    @include flexible();
    justify-content: center;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 25.5072px 23.1884px rgba(127, 84, 235, 0.25);

    &:active {
        background-color: $primary-active;
    }
}

@mixin notFound {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: $title-color;
    font-size: 22px;
    font-weight: 600;
}