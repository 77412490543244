@import "./src/common/commonStyles";

.link {
    width: 100%;
    cursor: pointer;
    background: white;
    border-radius: 12px;
    padding: 20px;
    @include flexible();
    justify-content: space-between;
    transition: all 0.4s ease;
    margin-bottom: 20px;

    &:active {
        background-color: #D9DDE3;
    }
}

.info {
   max-width: 80%;
}

.name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: $title-color;
    font-size: 16px;
    font-weight: 600;
}

.src {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(74, 99, 117, 0.5);
    font-size: 16px;
    font-weight: 600;
}

.imgContainer {
    & img {
        display: block;
    }
}