@import "./src/common/commonStyles";

.search {
    position: relative;

    & input {
        display: block;
        width: 100%;
        padding: 21px 50px 19px 25px;
        border: 2px solid rgba(8, 29, 63, 0.15);
        border-radius: 15px;
        font-size: 16px;
        color: $title-color;
        font-weight: 600;

        &::placeholder {
            color: rgba(8, 29, 63, 0.3);
        }
    }

    & label {
        position: absolute;
        top: 0;
        right: 23px;
        bottom: 0;
        margin: auto;
        @include flexible();
        & button {
            cursor: pointer;
            display: block;
            background-color: transparent;

            & img {
                display: block;
            }
        }
    }
}