@import "./src/common/commonStyles";

.scan {
    @include flexible();
    justify-content: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding: 20px 0;
    border-radius: 12px;
    & img {
        margin-right: 7px;
    }
}

.qr {
    background-color: $primary-color;
    color: white;
    box-shadow: 0 25.5072px 23.1884px rgba(127, 84, 235, 0.25);
    transition: all 0.4s ease;
    &:active {
        background-color: $primary-active;
    }
}